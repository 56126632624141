import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'
import { retry } from '../../utils/PromiseRetry';
import { PrivateRoute, ProtectedRoute, PublicRoute } from './routesConfig';

const Home = lazy(() => retry(() => import('../components/Home')));
const Latest = lazy(() => retry(() => import('../components/Latest')));
const Trending = lazy(() => retry(() => import('../components/Trending')));
const Pubsliher = lazy(() => retry(() => import('../components/Publisher')));
const NewsDetails = lazy(() => retry(() => import('../components/NewsDetails')));
const NotFound = lazy(() => retry(() => import('../components/404')));
const Login = lazy(() => retry(() => import('../components/UserLogin/Login')));
const LoginForm = lazy(() => retry(() => import('../components/UserLogin/LoginForm')));
const ResetPassword = lazy(() => retry(() => import('../components/UserLogin/ResetPassword')));
const SignupForm = lazy(() => retry(() => import('../components/UserLogin/SignupForm')));
const UserProfile = lazy(() => retry(() => import('../components/UserDashboard/UserProfile')));
const UserTaxInformation = lazy(() => retry(() => import('../components/UserDashboard/UserTaxInformation')));
const Transaction = lazy(() => retry(() => import('../components/Transaction/Transaction')));
const Candidate = lazy(() => retry(() => import('../components/UserDashboard/Candidate')));
const CategoryDashboard = lazy(() => retry(() => import('../components/Category/Category')));
const Curriculum = lazy(() => retry(() => import('../components/Curriculam/Curriculum')));
const CurriculumClassWise = lazy(() => retry(() => import('../components/Curriculam/CurriculumClassWise')));
const Leaderboard = lazy(() => retry(() => import('../components/UserDashboard/Leaderboard')));
const LeaderboardClassWise = lazy(() => retry(() => import('../components/UserDashboard/LeaderboardClassWise')));
const Analytics = lazy(() => retry(() => import('../components/Analytics/Analytics')));
const QuizSummary = lazy(() => retry(() => import('../components/UserDashboard/QuizSummary')));
const QuizSummaryClassWise = lazy(() => retry(() => import('../components/UserDashboard/QuizSummaryClassWise')));
const QuizSummaryStudents = lazy(() => retry(() => import('../components/UserDashboard/QuizSummaryStudents')));
const QuizSettings = lazy(() => retry(() => import('../components/UserDashboard/QuizSettings')));
const Signout = lazy(() => retry(() => import('../components/Signout')));
const QuizPage = lazy(() => retry(() => import('../components/Quiz')));
const QuizQuestionPage = lazy(() => retry(() => import('../components/Quiz/QuizQuestionPage')));
const ExpireLinkPage = lazy(() => retry(() => import('../components/UserLogin/ExpireLinkPage')));
const QuizScoreCard = lazy(() => retry(() => import('../components/Quiz/QuizScoreCard')));
const FacultyPage = lazy(() => retry(() => import('../components/Faculty/FacultyPage')));
const FacultyDetailsPage = lazy(() => retry(() => import('../components/Faculty/FacultyDetailsPage')));
const AssignmentSummary = lazy(() => retry(() => import('../components/Assignment/AssignmentSummary')));
const CreateAssignment = lazy(() => retry(() => import('../components/Assignment/CreateAssignment')));
const AssignmetAppearedDetails = lazy(() => retry(() => import('../components/Assignment/AssignmetAppearedDetails')));
const AssignmetAppearedCandidateWise = lazy(() => retry(() => import('../components/Assignment/AssignmetAppearedCandidateWise')));
const AssignmentQuestionPage = lazy(() => retry(() => import('../components/Assignment/AssignmentQuestionPage')));
const AssignmentDetails = lazy(() => retry(() => import('../components/Assignment/AssignmentDetails')));
const RecommendedForYou = lazy(() => retry(() => import('../components/RecommendedForYou/index')));
const RecentReads = lazy(() => retry(() => import('../components/RecentReads/index')));
const PublicationWiseNews = lazy(() => retry(() => import('../components/PublicationWiseNews/index')));
const Subscription = lazy(() => retry(() => import("../components/Subscription/index")));
const PricingPage = lazy(() => retry(() => import('../components/Pricing/PricingPage')));
const InstituteVerification = lazy(() => retry(() => import('../components/UserLogin/InstituteVerification')));
const UserStoriesSubmission = lazy(() => retry(() => import('../components/UserStories')));
const SubscriptionList = lazy(() => retry(() => import('../components/SubscriptionList')));
const SavedItems = lazy(() => retry(() => import('../components/SavedItems')));
const ThankYouPage = lazy(() => retry(() => import('../components/ThankYouPage')));
const SuccessfulSignup = lazy(() => retry(() => import('../components/SuccessfulSignup')));
const DeckPage = lazy(() => retry(() => import('../components/DeckPage')));
const DetailsDeckPage = lazy(() => retry(() => import('../components/DetailsDeckPage/index')));
const AutoRegistration = lazy(() => retry(() => import('../components/AutoRegistration/')));
const Categories = lazy(() => retry(() => import('../components/Categories')));
const CategoryWiseNews = lazy(() => retry(() => import('../components/CategoryWiseNews/index')));
const AssemblyNews = lazy(() => retry(() => import('../components/AssemblyNews/index')));

const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <NotFound />,
        children: [
            { path: "", element: <Home /> },
            { path: "latest", element: <Latest /> },
            { path: "trending", element: <Trending /> },
            { path: "recent-reads", element: <RecentReads /> },
            { path: "for-you", element: <PrivateRoute element={<RecommendedForYou />} pathName={"for-you"} /> },
            { path: "publisher", element: <Pubsliher /> },
            { path: "publication/details", element: <PublicationWiseNews /> },
            { path: "details/:slug", element: <NewsDetails /> },
            { path: "login", element: <PublicRoute element={<Login />} /> },
            { path: ":userSlug/login", element: <PublicRoute element={<LoginForm />} /> },
            { path: "reset-password/:userSlug", element: <ResetPassword /> },
            { path: ":userSlug/signup", element: <PublicRoute element={<SignupForm />} /> },
            { path: "reset-password", element: <ResetPassword /> },
            { path: ":userSlug/:customUrl", element: <PrivateRoute element={<UserProfile />} /> },
            { path: "tax", element: <ProtectedRoute element={<UserTaxInformation instituteAccess={true} />} /> },
            { path: "transaction", element: <ProtectedRoute element={<Transaction instituteAccess={true} individualAccess={true} />} /> },
            { path: "candidate", element: <ProtectedRoute element={<Candidate instituteAccess={true} />} /> },
            { path: "category", element: <ProtectedRoute element={<CategoryDashboard instituteAccess={true} />} /> },
            { path: "curriculum", element: <ProtectedRoute element={<Curriculum instituteAccess={true} />} /> },
            { path: "assembly-news", element: <ProtectedRoute element={<AssemblyNews instituteAccess={true} />} /> },
            { path: "curriculum/class", element: <ProtectedRoute element={<CurriculumClassWise instituteAccess={true} />} /> },
            { path: "leaderboard", element: <PrivateRoute element={<Leaderboard />} /> },
            { path: "leaderboard/class", element: <PrivateRoute element={<LeaderboardClassWise />} /> },
            { path: "analytics", element: <PrivateRoute element={<Analytics />} /> },
            { path: "quiz-summary", element: <PrivateRoute element={<QuizSummary />} /> },
            { path: "quiz-summary/class", element: <PrivateRoute element={<QuizSummaryClassWise />} /> },
            { path: "quiz-summary/user", element: <PrivateRoute element={<QuizSummaryStudents />} /> },
            { path: "quiz-settings", element: <ProtectedRoute element={<QuizSettings candidateAccess={true} individualAccess={true} />} /> },
            { path: "signout", element: <Signout /> },
            // { path: "quiz", element: <ProtectedRoute element={<QuizPage candidateAccess={true} individualAccess={true}/>} /> },
            { path: "quiz", element: <PublicRoute element={<QuizPage />} /> },

            { path: "quiz/:customUrl", element: <ProtectedRoute element={<QuizQuestionPage candidateAccess={true} facultyAccess={true} adminAccess={true} />} /> },
            { path: "link-expired", element: <PublicRoute element={<ExpireLinkPage />} /> },
            { path: "result/:customUrl", element: <ProtectedRoute element={<QuizScoreCard candidateAccess={true} facultyAccess={true} adminAccess={true} />} /> },
            { path: "faculty", element: <ProtectedRoute element={<FacultyPage instituteAccess={true} />} /> },
            { path: "faculty/history", element: <ProtectedRoute element={<FacultyDetailsPage instituteAccess={true} />} /> },
            { path: "assessment", element: <ProtectedRoute element={<AssignmentSummary instituteAccess={true} candidateAccess={true} />} /> },
            { path: "assessment/:assignmentType", element: <ProtectedRoute element={<CreateAssignment instituteAccess={true} />} /> },
            { path: "appeared-assessment", element: <ProtectedRoute element={<AssignmetAppearedDetails instituteAccess={true} />} /> },
            { path: "appeared-assessment/student", element: <ProtectedRoute element={<AssignmetAppearedCandidateWise instituteAccess={true} candidateAccess={true} />} /> },
            { path: "attempt-assignment/:customUrl", element: <ProtectedRoute element={<AssignmentQuestionPage candidateAccess={true} />} /> },
            { path: "assessment-details/:assignmentCode", element: <PrivateRoute element={<AssignmentDetails />} /> },
            { path: "subscription", element: <PrivateRoute element={<Subscription />} pathName={"subscription"} /> },
            { path: "pricing/:productType", element: <PrivateRoute element={<PricingPage />} pathName={"pricing/develop"} /> },
            { path: "institute/verify", element: <PublicRoute element={<InstituteVerification />} /> },
            { path: "user-story-submission", element: <PrivateRoute element={<UserStoriesSubmission />} pathName={"user-story-submission"} /> },
            { path: "subscription/:productType", element: <PublicRoute element={<SubscriptionList />} /> },
            { path: "saved-items", element: <PrivateRoute element={<SavedItems />} pathName={"saved-items"} /> },
            { path: "submission-contact-us", element: <PublicRoute element={<ThankYouPage />} /> },
            { path: "develop-submission-subscription", element: <PublicRoute element={<ThankYouPage />} /> },
            { path: "evolve-submission-subscription", element: <PublicRoute element={<ThankYouPage />} /> },
            { path: "successful-signup", element: <PublicRoute element={<SuccessfulSignup />} /> },
            { path: "deck", element: <PrivateRoute element={<DeckPage />} /> },
            { path: "deck/:name", element: <PrivateRoute element={<DetailsDeckPage />} /> },
            { path: "user-auto-registration/:slug", element: <PublicRoute element={<AutoRegistration />} /> },
            { path: "categories", element: <Categories /> },
            { path: "category/details", element: <CategoryWiseNews /> },
        ],
    },
]);


const AppRouter = () => {
    return (
        <Suspense fallback={
            <span style={{ textAlign: "center", display: "block" }}>
                <div style={{ display: "inline-block", marginTop: "300px" }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={false}
                        timeout={8000}
                    />
                </div>
            </span>}>
            <RouterProvider router={router} />
        </Suspense>
    );
}

export default AppRouter;
